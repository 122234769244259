import React from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalTitle,
} from "reactstrap";

import { confirmable, createConfirmation } from "react-confirm";

class ConfirmationOk extends React.Component {
  render() {
    // console.log(this.props);
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
    } = this.props;
    return (
      <Modal isOpen={true}>
      {/* <Modal
      show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
      > */}
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalBody>{confirmation}</ModalBody>
        <ModalFooter>
          <Button onClick={() => proceed(false)}>{cancelLabel}</Button>
          {/* <Button
            // className="btn"
            // bsStyle="primary"
            color="primary"
            onClick={() => proceed(true)}
          >
            {proceedLabel}
          </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmationOk.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirmOk(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(ConfirmationOk))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
