import React, { useState, createContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_USER, GET_AB } from "../gql/queries";
import { UPDATE_USER_AB, UPDATE_TOKEN } from "../gql/mutations";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const localUser = JSON.parse(localStorage.getItem("currentUser")) || {};
  const [refreshToken, setRefreshToken] = useState(
    localUser.refreshToken || null
  );
  const [token, setToken] = useState(localUser.token || null);
  const [userId, setUserId] = useState(localUser.userId || null);
  const [email, setEmail] = useState(localUser.email || null);
  const [abId, setAbId] = useState(localUser.abId || null);
  const [labId, setLabId] = useState(localUser.labId || null);
  const [abName, setAbName] = useState(localUser.abName || null);
  const [firstName, setFirstName] = useState(localUser.firstName || null);
  const [lastName, setLastName] = useState(localUser.lastName || null);
  const [role, setRole] = useState(localUser.role || null);
  const [expireTime, setExpireTime] = useState(localUser.expireTime || null);
  const [user, setUser] = useState(null);
  //Alerts
  const [alertOptions, setAlertOptions] = useState({});
  const [confirmAlert, setConfirmAlert] = useState(null);
  const [alertResponse, setAlertResponse] = useState({});
  const [alertData, setAlertData] = useState({});

  const [getUser, { loading, data, error }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log(data);
      setUser(data.login);
      // props.history.push("/");
    },
  });

  const [
    updateUserAb,
    { loading: aLoading, error: aError, data: aData },
  ] = useMutation(UPDATE_USER_AB, {
    onCompleted({ updateUserAb }) {
      // console.log(`${updateUserAb} updated`);
      // refetch();
    },
  });

/*   const [
    updateToken,
    { loading: tLoading, error: tError, data: tData },
  ] = useMutation(UPDATE_TOKEN, {
    onCompleted({ updateToken }) {
      console.log(`${updateToken} updated`);
      // refetch();
    },
  }); */

  /* class AuthContextProvider extends Component {
  constructor() {
    super();
    const localUser = JSON.parse(localStorage.getItem("currentUser")) || {};
    this.state = {
      token: localUser.token || null,
      userId: localUser.userId || null,
      abId: localUser.abId || null,
      labId: null,
      abName: localUser.abName || "",
      firstName: localUser.firstName || "",
      lastName: localUser.lastName || "",
      role: localUser.role || ""
    };
  } */

  const login = (
    refreshToken,
    token,
    userId,
    email,
    abId,
    abName,
    firstName,
    lastName,
    role,
    labId,
    expireTime,
    tokenExpiration
  ) => {
    console.log("login context");
    setRefreshToken(refreshToken);
    setToken(token);
    setUserId(userId);
    setEmail(email);
    setAbId(abId);
    setAbName(abName);
    setFirstName(firstName);
    setLastName(lastName);
    setRole(role);
    setLabId(labId);
    setExpireTime(expireTime);
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        refreshToken,
        token,
        userId,
        email,
        abId,
        abName,
        firstName,
        lastName,
        role,
        labId,
        expireTime,
      })
    );

    window.location.reload();
  };

  const getUserData = (email, password) => {
    getUser({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  const logout = () => {
    console.log("logout");
    // return;
    setToken(null);
    setUserId(null);
    localStorage.removeItem("currentUser");
    window.location.reload();
    // if (history) history.push("/login");
  };

/*   const updateTokenFunc = () => {
    console.log("update token here for " + userId);
    console.log(refreshToken);
    console.log(token);

    updateToken({
      variables: {
        token,
        refreshToken,
      },
    });
    if (tError) {
      console.log(tError);
    }
  }; */
  const changeAb = (newAbId, newAbName) => {
    /* console.log(newAbId, newAbName);
    getAb({
      variables: {
        id: newAbId,
      },
    }); */
    // logout, then login to new ab
    // also change the ab on the user table to the new
    setAbId(newAbId);
    setAbName(newAbName);
    // console.log(token, userId, abId, abName, firstName, lastName, role);
    // localStorage.removeItem("currentUser");
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        refreshToken,
        token,
        userId,
        email,
        abId: newAbId,
        abName: newAbName,
        firstName,
        lastName,
        role,
        expireTime,
      })
    );
    // window.location.reload();

    updateUserAb({
      variables: {
        id: userId,
        abId: newAbId,
      },
    });
    if (aError) {
      console.log(aError);
      alert(aError);
    }
  };

  const toggleConfirmAlert = () => {
    // setAlertTitle("Alert Title");
    setConfirmAlert(!confirmAlert);
  };

  return (
    <AuthContext.Provider
      value={{
        // ...this.state,
        refreshToken,
        token,
        userId,
        email,
        abId,
        abName,
        firstName,
        lastName,
        role,
        labId,
        expireTime,
        user,
        getUserData,
        login,
        logout,
        // updateTokenFunc,
        changeAb,
        confirmAlert,
        alertOptions,
        setAlertOptions,
        toggleConfirmAlert,
        alertResponse,
        setAlertResponse,
        alertData,
        setAlertData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
