// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
// import App2 from "./App2";
import App from "./App4";
import * as serviceWorker from "./serviceWorker";
// import AuthContextProvider from "./contexts/AuthContext";

// import { ApolloClient } from "apollo-boost";
import { createUploadLink } from "apollo-upload-client";
import {
  ApolloClient,
  // ApolloLink,
  // InMemoryCache,
  // HttpLink
} from "apollo-client";

import { ApolloLink, fromPromise } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
// import { HttpLink } from "apollo-link-http";

import { ApolloProvider } from "@apollo/react-hooks";
import { UPDATE_TOKEN } from "./gql/mutations";
// import gql from "graphql-tag";
import { confirmOk } from "./views/Alerts/ConfirmationOk";

const errorLinkX = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      console.log(locations);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const getNewToken = async () => {
  const localUser = JSON.parse(localStorage.getItem("currentUser")) || {};
  // console.log('localUser from index.js ');
  // console.log(localUser);
  const token = localUser.token;
  const refreshToken = localUser.refreshToken;
  // console.log(token, refreshToken);

  const t = await client.mutate({
    mutation: UPDATE_TOKEN,
    variables: { token, refreshToken },
  });
  const tObj = t.data.updateToken;

  localStorage.setItem(
    "currentUser",
    JSON.stringify({
      refreshToken: tObj.refreshToken,
      token: tObj.token,
      userId: localUser.userId,
      email: localUser.email,
      abId: localUser.abId,
      abName: localUser.abName,
      firstName: localUser.firstName,
      lastName: localUser.lastName,
      role: localUser.role,
      expireTime: localUser.expireTime,
    })
  );

  return t;

  /*
  return {
    accessToken: "123",
    refreshToken: "456",
  }; */
};

// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

// eslint-disable-line no-loop-func
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // console.log(err.extensions.code);
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        console.log(err);
        // switch (err.extensions.code) {
        switch (err.message) {
          /* case "User not found or invalid token":
            alert(err.message);
            // return;
            break; */
          case "Password is incorrect":
          case "User does not exist":
            // alert(err.message);
            confirmOk(err.message, "Authentication", "OK", {
              title: `Authentication Error`,
            })
          case "Unauthenticated":
          case "User not found or invalid token":
          case "jwt expired":
          case "Invite User Email exists already":
            // case "User not found or invalid token":
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                getNewToken()
                  // .then(({ accessToken, refreshToken }) => {
                  .then((res) => {
                    if (res) {
                      // console.log(res);
                      const resObj = res.data.updateToken;
                      // console.log(resObj.token, resObj.refreshToken);
                      // Store the new tokens for your auth link

                      resolvePendingRequests();
                      return resObj.token;
                    } else {
                      console.log("no res");
                    }
                  })
                  .catch((error) => {
                    // console.log(error);
                    pendingRequests = [];
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    return;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).filter((value) => Boolean(value));
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve());
                })
              );
            }

            return forward$.flatMap(() => forward(operation));
          case "Email exists already":
            alert(err.message);
            break;

          default:
          // code block
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
);

const cache = new InMemoryCache();
/* const httpLink = new HttpLink({
  uri: "http://localhost:4100/graphql"
}); */
// https://www.youtube.com/watch?v=KQ_ty4A6Nsc
// const uploadLink = createUploadLink({ uri: "http://localhost:4100/graphql" });

let uploadLink;
if (process.env.REACT_APP_DOCKER === "true") {
  // TODO cmerkley test if actually works without signed cert
  uploadLink = createUploadLink({ uri: "https://ab.qamanager.local/graphql" });
} else if (process.env.REACT_APP_ENV === "staging") {
  uploadLink = createUploadLink({ uri: "https://ab-stg.qamanager.com/graphql" });
} else if (process.env.NODE_ENV === "development") {
  uploadLink = createUploadLink({ uri: "http://localhost:4100/graphql" });
} else {
  uploadLink = createUploadLink({
    uri: "https://gql.ab.qamanager.com/graphql",
  });
}

/* const client = new ApolloClient({
  cache,
  link,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
}); */

/* const client = new ApolloClient({
  cache,
  link,
  request: (operation) => {
    // const token = localStorage.getItem('token');
    const currentUser = localStorage.getItem('currentUser');
    // alert(currentUser);
    // const token = currentUser ? currentUser.token : '';
    const token = '12345';
    operation.setContext({
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
}) */

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const localUser = JSON.parse(localStorage.getItem("currentUser")) || {};
  // console.log('localUser from index.js ');
  // console.log(localUser);
  const token = localUser.token;
  const refreshToken = localUser.refreshToken;
  // console.log(token);
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      refresh: refreshToken ? `Bearer ${refreshToken}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

// https://stackoverflow.com/questions/47879016/how-to-disable-cache-in-apollo-link-or-apollo-client
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(uploadLink)), // Chain links
  cache,
  defaultOptions: defaultOptions,
});

// test
/* client
  .query({
    query: gql`
      query GetLab {
        lab(abId: 53, id: 45) {
          _id
          labIdNo
        }
      }
    `
  })
  .then(result => console.log(result)); */

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
